import doc1 from '../../assets/documents/ro/Formular proces verbal reclamatii anvelope.pdf';

const documents = (t) => [
  {
    name: 'Formular proces verbal reclamatii anvelope',
    url: doc1,
    type: 'pdf',
  },
];

export default documents;
