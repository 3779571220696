import i18n from '../i18n';

const getCurrency = (lang) => {
  switch (lang) {
    case 'sl':
      return '€';
    case 'bg':
      return 'лв';
    case 'ro':
      return 'Lei';
    default:
      return '€';
  }
};

export const formatPriceWithCurrency = (price) => {
  const lang = i18n.language;
  return `${price} ${getCurrency(lang)}`;
};

// return new Intl.NumberFormat('bg', {
//   style: 'currency',
//   currency: 'LEV',
//   currencyDisplay: 'symbol',
// }).format(price);

// const getNavigatorLanguage = () =>
//   navigator.languages && navigator.languages.length
//     ? navigator.languages[0]
//     : navigator.userLanguage ||
//       navigator.language ||
//       navigator.browserLanguage ||
//       'en';
