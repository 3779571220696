import doc1 from '../../assets/documents/ro/Continental AllSeason Contact 2.pdf';
import doc2 from '../../assets/documents/ro/Continental Manual Anvelope.pdf';
import doc3 from '../../assets/documents/ro/Continental SSR.pdf';
import doc4 from '../../assets/documents/ro/Continental UltraContact NXT.pdf';
import doc5 from '../../assets/documents/ro/Premii anvelope vară Continental.pdf';

const documents = (t) => [
  {
    name: 'Continental AllSeason Contact ',
    url: doc1,
    type: 'pdf',
  },
  {
    name: 'Continental Manual Anvelope',
    url: doc2,
    type: 'pdf',
  },
  {
    name: 'Continental SSR',
    url: doc3,
    type: 'pdf',
  },
  {
    name: 'Continental UltraContact NXT',
    url: doc4,
    type: 'pdf',
  },
  {
    name: 'Premii anvelope vară Continental',
    url: doc5,
    type: 'pdf',
  },
];

export default documents;
