import {Component} from 'react';

// components
import GuaranteeDocuments from '../../components/GuaranteeDocuments/GuaranteeDocuments';
import ModelsDocuments from '../../components/ModelsDocuments/ModelsDocuments';
import WarrantyDocuments from '../../components/WarrantyDocuments/WarrantyDocuments';

// style components
import DocumentTypeContainer from './style/DocumentTypeContainer';
import Title from './style/Title.style';
import Container from './style/Container.style';

// translations
import {withTranslation} from 'react-i18next';

class DocumentsContainer extends Component {
  render() {
    const t = this.props.t;
    return (
      <Container>
        {/* <DocumentTypeContainer>
          <div>
            {' '}
            <Title>{t('miniPriceCatalog')}</Title>
            <Button
              onClick={() => {
                this.downloadPriceList();
              }}
            >
              Презентация Continental TS 870 P
              <Icon className={`mdi mdi-file-pdf-outline`}></Icon>
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                this.openSharepointLink();
              }}
            >
              Обучение летни гуми Continental
            </Button>
          </div>
        </DocumentTypeContainer> */}

        <DocumentTypeContainer>
          <Title>{t('additionalGuarantee')}</Title>
          <GuaranteeDocuments />
        </DocumentTypeContainer>
        <DocumentTypeContainer>
          <Title>{t('warranty')}</Title>
          <WarrantyDocuments />
        </DocumentTypeContainer>
        <DocumentTypeContainer>
          <Title>{t('actualFlyers')}</Title>
          <ModelsDocuments />
        </DocumentTypeContainer>
      </Container>
    );
  }
}

export default withTranslation()(DocumentsContainer);
