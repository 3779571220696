import doc1 from '../../assets/documents/ro/Declaratie de conformitate si certificat garantie.pdf';
import doc2 from '../../assets/documents/ro/Notificare RAR 1.pdf';

const documents = (t) => [
  {
    name: 'Declaratie de conformitate si certificat garantie',
    url: doc1,
    type: 'pdf',
  },
  {
    name: 'Notificare RAR',
    url: doc2,
    type: 'pdf',
  },
];

export default documents;
